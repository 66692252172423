import AutycomCover from "../src/assets/img/caseStudies/autycom-cover.png";
import AutycomProblems from "../src/assets/img/caseStudies/autycom-problems.png";
import AutycomResults from "../src/assets/img/caseStudies/autycom-results.png";
import AemaCover from "../src/assets/img/caseStudies/aema-cover.png";
import AemaProblems from "../src/assets/img/caseStudies/aema-problems.png";
import AemaResults from "../src/assets/img/caseStudies/aema-results.png";
import CrushedCover from "../src/assets/img/caseStudies/crushed-cover.png";
import CrushedProblems from "../src/assets/img/caseStudies/crushed-problems.png";
import CrushedResults from "../src/assets/img/caseStudies/crushed-results.png";
import DigitabCover from "../src/assets/img/caseStudies/ditab-cover.png";
import DigitabProblems from "../src/assets/img/caseStudies/ditab-problems.png";
import DigitabResults from "../src/assets/img/caseStudies/ditab-results.png";
import FluxCover from "../src/assets/img/caseStudies/flux-cover.png";
import FluxProblems from "../src/assets/img/caseStudies/flux-problems.png";
import FluxResults from "../src/assets/img/caseStudies/flux-results.png";
import GarreCover from "../src/assets/img/caseStudies/garre-cover.png";
import GarreProblems from "../src/assets/img/caseStudies/garre-problems.png";
import GarreResults from "../src/assets/img/caseStudies/garre-results.png";
import HaigCover from "../src/assets/img/caseStudies/haig-cover.png";
import HaigProblems from "../src/assets/img/caseStudies/haig-problems.png";
import HaigResults from "../src/assets/img/caseStudies/haig-results.png";
import PoscoCover from "../src/assets/img/caseStudies/posco-cover.png";
import PoscoProblems from "../src/assets/img/caseStudies/posco-problems.png";
import PoscoResults from "../src/assets/img/caseStudies/posco-results.png";
import ZplendidCover from "../src/assets/img/caseStudies/zplendid-cover.png";
import ZplendidProblems from "../src/assets/img/caseStudies/zplendid-problems.png";
import ZplendidResults from "../src/assets/img/caseStudies/zplendid-results.png";
import awsLogo from "../src/assets/img/services/tool-aws.png";
import djangoLogo from "../src/assets/img/services/tool-django.png";
import dogItCover from "../src/assets/img/caseStudies/dogit-cover.png";
import dogItProblems from "../src/assets/img/caseStudies/dogit-problems.png";
import dogItResults from "../src/assets/img/caseStudies/dogit-results.png";
import graphQlLogo from "../src/assets/img/services/tool-postgre.png";
import nativisCover from "../src/assets/img/caseStudies/nativis-cover.png";
import nativisProblems from "../src/assets/img/caseStudies/nativis-problems.png";
import nativisResults from "../src/assets/img/caseStudies/nativis-results.png";
import pythonLogo from "../src/assets/img/services/tool-python.png";
import reactLogo from "../src/assets/img/services/tool-react.png";
import stripeLogo from "../src/assets/img/services/tool-stripe.png";
import superPorteroCover from "../src/assets/img/caseStudies/sp-cover.png";
import superPorteroProblems from "../src/assets/img/caseStudies/sp-problems.png";
import superPorteroResults from "../src/assets/img/caseStudies/sp-results.png";
import vueLogo from "../src/assets/img/services/tool-vue.png";

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export function useCasesData() {
  const case_studies = shuffleArray([
    {
      name: "Super Portero",
      image_cover: superPorteroCover,
      image_problems: superPorteroProblems,
      image_results: superPorteroResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "sp-challenge",
      solution: "sp-solution",
      outcome: "sp-outcome",
      type: "mobile",
      slug: "super-portero",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "Stripe",
          img: stripeLogo,
        },
      ],
      checkbuttons: [
        {
          desc: "sp-solution1",
        },
        {
          desc: "sp-solution2",
        },
        {
          desc: "sp-solution3",
        },
        {
          desc: "sp-solution4",
        },
        {
          desc: "sp-solution5",
        },
        {
          desc: "sp-solution6",
        },
      ],
    },
    {
      name: "Nativis Projex",
      image_cover: nativisCover,
      image_problems: nativisProblems,
      image_results: nativisResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "nativis-challenge",
      solution: "nativis-solution",
      outcome: "nativis-outcome",
      type: "mobile",
      slug: "nativis-projex",
      tecnologies: [
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Dog it",
      image_cover: dogItCover,
      image_problems: dogItProblems,
      image_results: dogItResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "dogit-challenge",
      solution: "dogit-solution",
      outcome: "dogit-outcome",
      type: "mobile",
      slug: "dog-it",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "Stripe",
          img: stripeLogo,
        },
      ],
      checkbuttons: [
        {
          desc: "dogit-solution1",
        },
        {
          desc: "dogit-solution2",
        },
        {
          desc: "dogit-solution3",
        },
        {
          desc: "dogit-solution4",
        },
        {
          desc: "dogit-solution5",
        },
      ],
    },
    {
      name: "Garré",
      image_cover: GarreCover,
      image_problems: GarreProblems,
      image_results: GarreResults,
      plataform: "Web",
      services: "User experience, development, cloud services",
      challenge: "garre-challenge",
      solution: "garre-solution",
      outcome: "garre-outcome",
      type: "web",
      slug: "garre",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "Vue.js",
          img: vueLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Flux",
      image_cover: FluxCover,
      image_problems: FluxProblems,
      image_results: FluxResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "flux-challenge",
      solution: "flux-solution",
      outcome: "flux-outcome",
      type: "mobile",
      slug: "flux",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "Stripe",
          img: stripeLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Crushed!?",
      image_cover: CrushedCover,
      image_problems: CrushedProblems,
      image_results: CrushedResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "crushed-challenge",
      solution: "crushed-solution",
      outcome: "crushed-outcome",
      type: "mobile",
      slug: "crushed",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "Stripe",
          img: stripeLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Howamigoing?",
      image_cover: HaigCover,
      image_problems: HaigProblems,
      image_results: HaigResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "haig-challenge",
      solution: "haig-solution",
      outcome: "haig-outcome",
      type: "web",
      slug: "howamigoing",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "GraphQL",
          img: graphQlLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Digitab",
      image_cover: DigitabCover,
      image_problems: DigitabProblems,
      image_results: DigitabResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "digitab-challenge",
      solution: "digitab-solution",
      outcome: "digitab-outcome",
      type: "mobile",
      slug: "digitab",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "Stripe",
          img: stripeLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Zplendid",
      image_cover: ZplendidCover,
      image_problems: ZplendidProblems,
      image_results: ZplendidResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "zplendid-challenge",
      solution: "zplendid-solution",
      outcome: "zplendid-outcome",
      type: "mobile",
      slug: "zplendid",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "GraphQL",
          img: graphQlLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Posco",
      image_cover: PoscoCover,
      image_problems: PoscoProblems,
      image_results: PoscoResults,
      plataform: "iOS, Android",
      services: "User experience, development, cloud services",
      challenge: "posco-challenge",
      solution: "posco-solution",
      outcome: "posco-outcome",
      type: "mobile",
      slug: "posco",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React Native",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
        {
          name: "GraphQL",
          img: graphQlLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Autycom",
      image_cover: AutycomCover,
      image_problems: AutycomProblems,
      image_results: AutycomResults,
      plataform: "Mobile, Web",
      services: "User experience, development, cloud services",
      challenge: "autycom-challenge",
      solution: "autycom-solution",
      outcome: "autycom-outcome",
      type: "web",
      slug: "autycom",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },
      ],
      checkbuttons: [],
    },
    {
      name: "Aema",
      image_cover: AemaCover,
      image_problems: AemaProblems,
      image_results: AemaResults,
      plataform: "Mobile, Web",
      services: "User experience, e-commerce, cloud services",
      challenge: "aema-challenge",
      solution: "aema-solution",
      outcome: "aema-outcome",
      type: "web",
      slug: "grupo-aema",
      tecnologies: [
        {
          name: "AWS",
          img: awsLogo,
        },
        {
          name: "React",
          img: reactLogo,
        },
        {
          name: "Python",
          img: pythonLogo,
        },
        {
          name: "Django",
          img: djangoLogo,
        },

      ],
      checkbuttons: [],
    },
  ]);

  return case_studies;
}
